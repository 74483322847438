import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { Map } from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class MapInteractionService {
  public drawCreate: Subject<any> = new Subject();
  public drawUpdate: Subject<any> = new Subject();
  public drawDelete: Subject<any> = new Subject();
  public drawSelect: Subject<any> = new Subject();
  public drawMode: BehaviorSubject<string> = new BehaviorSubject('static');
  public map;

  constructor() {

  }

  public bind(map: Map) {
    this.map = map;
    map.on('draw.create', this.triggerDrawCreate.bind(this));
    map.on('draw.delete', this.triggerDrawDelete.bind(this));
    map.on('draw.update', this.triggerDrawUpdate.bind(this));
    map.on('draw.selectionchange', this.triggerDrawSelect.bind(this));
    map.on('draw.modechange', this.triggerDrawModeChange.bind(this));
  }

  public unbind(map: Map) {
    map.off('draw.create', this.triggerDrawCreate);
    map.off('draw.delete', this.triggerDrawDelete);
    map.off('draw.update', this.triggerDrawUpdate);
    map.off('draw.selectionchange', this.triggerDrawSelect);
    map.off('draw.modechange', this.triggerDrawModeChange);
  }

  public triggerDrawCreate(e: any) {
    e.features.forEach(f => {
      this.drawCreate.next(f);
    });
  }

  public triggerDrawUpdate(e: any) {
    e.features.forEach(f => {
      this.drawUpdate.next(f);
    });
  }

  public triggerDrawDelete(e: any) {
    e.features.forEach(f => {
      this.drawDelete.next(f);
    });
  }

  public triggerDrawSelect(e: any) {
    this.drawSelect.next(e.features);
  }

  public triggerDrawModeChange(e: {mode: string}) {
    this.drawMode.next(e.mode);
  }

  public getMapBearing() {
    return this.map.getBearing();
  }

  public getMapZoom() {
    return this.map.getZoom();
  }

  public setMapBearing(bearing: number) {
    this.map.easeTo({
      bearing: bearing
    });
  }

  public getMapCanvas() {
    return this.map.getCanvas();
  }

  public getCursor(mode) {

  }


}
